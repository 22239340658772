<!--
****************************************************************************************************
***                                           Tabs                                               ***
****************************************************************************************************
Composant de tabs, la gestion de tabs avec des routes est à priviligier.
*************
*** PROPS ***
*************
  - tabs
    C'est un tableau de propriétés pour les Tabs le format [{ label: 'Tab1', route: routeTab1 }]
    Exemple :
    <app-tab :tabs="[
      { label: 'Infos', route: { name: 'informations', params: { id: 453-324 } } },
      { label: 'Actions', route: { name: 'actions', params: { id: 453-324 } } }]" />
-->
<template>
  <div class="app-tabs grid-x">
    <router-link class="tab cell small-12 medium-3 large-1"
      v-for="tab in tabs"
      :key="`tab-${tab.label}`"
      :to="tab.route">
        {{ tab.label }}
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="sass">
.app-tabs
  background-color: $white
  padding: 0 48px
  display: flex
  justify-content: space-between
  border-bottom: 1px $line solid
  text-align: center
  .tab
    padding: 12px 0
    color: $black
    background-color: $white
    text-decoration: none
    white-space: nowrap
    &.router-link-active
      border-bottom: 2px $primary solid
</style>
