<template>
  <section class="sitemap">
    <div class="header">
      <h1>Plan du site</h1>
      <a href="/sitemap.xml" target="blank">(Format XML)</a>
    </div>

    <app-tabs
      :tabs="tabs"
    />
    <ul class="body" v-if="!error">
      <li class="subsitemap" v-for="sec in filterSubSitemap()" :key="sec.section">
        <h2 v-if="sec.section && activeTab === 7">{{ `${departements.find((d) => d.id.toString() === sec.section).name},` || '' }} les communes où construire :</h2>
        <h2 v-if="sec.section && activeTab === 6">{{ sec.section }}</h2>
        <ul class="subsitemap-section">
          <li v-for="link in sec.links" :key="link.url">
            <router-link :to="link.url.split('maisons-alysia.com')[1]">{{ link.name }}</router-link>
          </li>
        </ul>
      </li>
    </ul>
    <div class="error" v-else >
      <p>{{ error }}</p>
    </div>
  </section>
</template>

<script>
import AppTabs from '@/components/layouts/AppTabs.vue';
import sitemapApi from '@/services/api/sitemap';
import departements from '@/data/departments.json';

export default {
  name: 'articles',
  components: {
    AppTabs,
  },
  data() {
    return {
      departements,
      subSitemaps: [],
      error: '',
      activeTab: 0,
      tabs: [
        {
          label: 'Maisons Alysia',
          route: { name: 'sitemap', params: { tab: 'maisons-alysia' } },
        },
        {
          label: 'Votre projet',
          route: { name: 'sitemap', params: { tab: 'your-project' } },
        },
        {
          label: 'Votre maison',
          route: { name: 'sitemap', params: { tab: 'your-home' } },
        },
        {
          label: 'Votre terrain',
          route: { name: 'sitemap', params: { tab: 'your-land' } },
        },
        {
          label: 'Votre constructeur',
          route: { name: 'sitemap', params: { tab: 'your-builder' } },
        },
        {
          label: 'Nos outils',
          route: { name: 'sitemap', params: { tab: 'our-tools' } },
        },
        {
          label: 'Nos agences',
          route: { name: 'sitemap', params: { tab: 'our-agencies' } },
        },
        {
          label: 'Où construire',
          route: { name: 'sitemap', params: { tab: 'where-to-build' } },
        },
        {
          label: 'Articles',
          route: { name: 'sitemap', params: { tab: 'articles' } },
        },
      ],
    };
  },
  watch: {
    $route(to) {
      this.handleClick(to.params.tab);
    },
  },
  async mounted() {
    const xml = await sitemapApi.getSitemapIndex();
    const parser = new DOMParser();
    const resultXML = parser.parseFromString(xml, 'application/xml');

    const errorNode = resultXML.querySelector('parsererror');
    if (errorNode) {
      this.error = 'Error while parsing the XML file.';
    } else {
      const sitemapXML = resultXML.getElementsByTagName('sitemap');
      if (sitemapXML !== undefined) {
        const subSitemapNames = [];
        const subSitemapPromises = [...sitemapXML].map((el) => {
          const url = new URL(el.childNodes[1].innerHTML);
          const name = /\/sitemap-(.*).xml/;
          subSitemapNames.push(url.pathname.match(name)[1]);
          return sitemapApi.getSubSitemap(url.pathname);
        });
        const subSitemapResults = await Promise.all(subSitemapPromises);

        this.subSitemaps = subSitemapResults.map((el, i) => {
          const subSitemapParser = new DOMParser();
          const subSitemapResultXML = subSitemapParser.parseFromString(el, 'application/xml');
          const parsingError = subSitemapResultXML.querySelector('parsererror');
          if (parsingError) {
            this.error = 'Error while parsing the XML file';
          }
          const locs = [...subSitemapResultXML.getElementsByTagName('loc')];

          const subSitemap = {
            label: subSitemapNames[i],
            content: [],
          };

          if (subSitemap.label === 'where-to-build') {
            const locsByDept = locs.reduce((acc, loc) => {
              const dept = loc.innerHTML.split('/').at(-2);
              if (/[0-9]+/.test(dept)) {
                if (acc[dept]) {
                  acc[dept].push(loc);
                } else {
                  acc[dept] = [loc];
                }
              } else {
                // Constructeur maison
                const deptSplit = loc.innerHTML.split('/').at(-1).split('-').at(-1);
                if (acc[Number(deptSplit)]) {
                  acc[Number(deptSplit)].unshift(loc);
                } else {
                  acc[Number(loc.innerHTML.split('/').at(-1))] = [loc];
                }
              }
              return acc;
            }, {});
            subSitemap.content = Object.entries(locsByDept).map((dept) => ({
              section: dept[0],
              links: dept[1].map((loc) => ({
                name: this.locName(loc),
                url: loc.innerHTML,
              })),
            }));
          } else if (subSitemap.label === 'our-agencies') {
            const locsByDept = locs.reduce((acc, loc) => {
              let dept = loc.innerHTML.split('/').at(-2);
              if (dept === 'agences') {
                dept = loc.innerHTML.split('/').at(-1);
                if (acc[dept]) {
                  acc[dept].unshift(loc);
                } else {
                  acc[dept] = [loc];
                }
                return acc;
              }
              if (acc[dept]) {
                acc[dept].push(loc);
              } else {
                acc[dept] = [loc];
              }
              return acc;
            }, {});
            subSitemap.content = Object.entries(locsByDept).map((dept) => ({
              section: this.capitalizeAllFirstLetter(dept[0].replace(/-/g, ' ')),
              links: dept[1].map((loc) => ({
                name: this.locName(loc),
                url: loc.innerHTML,
              })),
            }));
          } else {
            subSitemap.content = [{
              section: '',
              links: locs.map((loc) => ({
                name: this.locName(loc),
                url: loc.innerHTML,
              })),
            }];
            subSitemap.content.sort((a, b) => a.name.localeCompare(b.name));
          }
          return subSitemap;
        });
      }
    }
    this.handleClick(this.$route.params.tab);
  },
  methods: {
    handleClick(value) {
      this.activeTab = this.tabs.findIndex((t) => t.route.params.tab === value);
    },
    filterSubSitemap() {
      const res = this.subSitemaps.filter((s) => s.label === this.tabs[this.activeTab].route.params.tab);
      return res.length ? res[0].content : [];
    },
    capitalizeAllFirstLetter(text) {
      return text.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
    },
    locName(loc) {
      if (loc.innerHTML.includes('/agences/')) {
        const agency = this.capitalizeAllFirstLetter(loc.innerHTML.split('/').at(-1).replace(/-/g, ' '));
        return agency;
      }
      return this.capitalizeAllFirstLetter(loc.innerHTML.split('/').at(-1).replace(/-/g, ' '));
    },
  },
};
</script>

<style lang="sass">
.sitemap
  background-color: $medium-bg
  .header
    @include header
    background-color: $white
    text-align: center
    box-shadow: none
    h1
      color: #514f4c
      margin-top: 54px
    a
      display: inline-block
      margin-bottom: 54px
  .body
    padding: 64px 0
    display: flex
    flex-direction: column
    gap: 3rem
    ul
      padding: 0
    .subsitemap
      display: flex
      flex-direction: column
      gap: 2rem
      max-width: 1280px
      width: 90%
      margin: 0 auto
    .subsitemap-section
      display: grid
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      row-gap: 1.5rem
      column-gap: 0.5rem
    li a
      color: $black
      display: block
      &::first-letter
        text-transform: capitalize
  .error
    padding: 64px 0
    text-align: center
</style>
