import axios from 'axios';

/**
 * Récupère le fichier xml du plan du site
 */
const getSitemapIndex = async () => {
  try {
    const response = await axios.get('/sitemap.xml');

    return response.data;
  } catch (er) {
    throw er;
  }
};

const getSubSitemap = async (url) => {
  try {
    const response = await axios.get(url);

    return response.data;
  } catch (er) {
    throw er;
  }
};

const sitemap = {};
sitemap.getSitemapIndex = getSitemapIndex;
sitemap.getSubSitemap = getSubSitemap;
export default sitemap;
